import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Lightbox from "react-image-lightbox"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"

import LightboxImageWrapper from "../components/global/lightbox/lightbox-image-wrapper"
import Seo from "../components/global/seo/seo.jsx"
const KonikHuniFiltre = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(relativePath: { eq: "filtre-galeri/hunifiltre1.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(
        relativePath: { eq: "filtre-galeri/konik-filtre-cesitleri.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim3: file(relativePath: { eq: "filtre-galeri/konik-filtre.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim4: file(relativePath: { eq: "filtre-galeri/hunifiltre.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim5: file(relativePath: { eq: "filtre-galeri/hunifiltre2.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim6: file(relativePath: { eq: "filtre-galeri/hunifiltre5.jpg" }) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const thumbArray = [
    { imgName: "resim3", imgAlt: "Konik Filtre" },
    { imgName: "resim4", imgAlt: "Paslanmaz Huni Filtre İmalatı" },
    { imgName: "resim5", imgAlt: "Paslanmaz Huni Filtre İmalatı" },
    { imgName: "resim6", imgAlt: "Paslanmaz Huni Filtre İmalatı" },
  ]
  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
    data.resim3.childImageSharp.full.src,
    data.resim4.childImageSharp.full.src,
    data.resim5.childImageSharp.full.src,
    data.resim6.childImageSharp.full.src,
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Konik - Huni Filtre İmalatı - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda paslanmaz huni filtre imalatı yapmaktadır. Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak istediğiniz ölçü, numune yada projeye göre paslanmaz huni filtre imalatını gerçekleştirmekteyiz."
        keywords="Konik filtre,Konik filtre fiyatları,Konik filtre imalatı,kesik konik filtre,kesik konik filtre imalatı,kesik konik filtre fiyatları,huni filtre,huni filtre imalatı,huni filtre fiyatları"
        url="konik-huni-filtre-imalati"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />

      <Box my={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <Box textAlign="center">
                  <Typography variant="h4" component="h1">
                    <Box fontWeight={500}>Konik Huni Filtre İmalatı</Box>
                  </Typography>
                </Box>
                <Lead>
                  Firmamız proje çizimine yada numuneye göre müşterilerimizin
                  istekleri doğrultusunda <span>paslanmaz huni filtre</span>{" "}
                  imalatı yapmaktadır.
                </Lead>
                <Lead>
                  Göz aralıkları mikronlar ile ölçülebilen mesh telleri
                  kullanılarak istediğiniz ölçü, numune yada projeye göre{" "}
                  <span>paslanmaz huni filtre</span> imalatını
                  gerçekleştirmekteyiz.
                </Lead>
                <Lead>
                  Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" pt={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
                <Box p={3}>
                  <FiltreKullanimAlani
                    align="center"
                    title="Paslanmaz Huni Filtre Kullanım Alanları"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} pt={3}>
                <div onClick={() => openLightBox(0)}>
                  <LightboxImageWrapper
                    fluid={data.resim1.childImageSharp.thumb}
                    imgAlt="Paslanmaz Huni Filtre İmalatı"
                    imgClass=""
                  />
                </div>
                <Box py={3}>
                  <div onClick={() => openLightBox(1)}>
                    <LightboxImageWrapper
                      fluid={data.resim2.childImageSharp.thumb}
                      imgAlt="Paslanmaz Filtre ve Elek Mesh Teller"
                      imgClass=""
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box py={3}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          {thumbArray.map((resim, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div onClick={() => openLightBox(index + 2)}>
                <LightboxImageWrapper
                  fluid={data[resim.imgName].childImageSharp.thumb}
                  imgClass=""
                  imgAlt={resim.imgAlt}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box my={3}>
        <FiltreGaleri />
      </Box>

      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default KonikHuniFiltre
